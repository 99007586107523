import { Header } from "../../components/header/header";
import { Offer } from "../../components/offer/offer";
import { About } from "../../components/about/about";
import { Contact } from "../../components/contact/contact";
import { Bg } from "../../components/bg/bg";
import { Footer } from "../../components/footer/footer";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type {
	AboutType,
	HeaderType,
	OfferType,
	ContactType,
	FooterType,
} from "../../types/strapi-types";
import { Loader } from "../../components/loader/loader";

export function MainPage() {
	const { data: headerData } = useStrapiFetch<HeaderType>(
		"/api/header?populate=bg&populate=logo"
	);
	const { data: offerData } = useStrapiFetch<OfferType[]>(
		"/api/offers?populate=image"
	);
	const { data: aboutData } = useStrapiFetch<AboutType>(
		"/api/about?populate=image"
	);
	const { data: contactData } = useStrapiFetch<ContactType>("/api/contact");
	const { data: footerData } = useStrapiFetch<FooterType>("/api/footer");

	if (!headerData || !offerData || !aboutData || !contactData || !footerData) {
		return <Loader />;
	}

	return (
		<>
			<Header data={headerData} />

			<main className="relative">
				<Bg />

				<Offer data={offerData} />

				<About data={aboutData} />

				<Contact data={contactData} />
			</main>

			<Footer data={footerData} />
		</>
	);
}
