import { NavLink } from "react-router-dom";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function Logo({
	url,
	alternativeText,
}: {
	url: string;
	alternativeText: string;
}) {
	return (
		<NavLink to="/" className="block w-32 h-24 relative">
			<img
				src={getStrapiImageUrl(url)}
				alt={alternativeText}
				className="absolute top-0 left-0 w-full h-full object-cover"
			/>
		</NavLink>
	);
}
