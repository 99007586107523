import { Header } from "../../components/header/header";
import { Contact } from "../../components/contact/contact";
import { Bg } from "../../components/bg/bg";
import { Footer } from "../../components/footer/footer";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type {
	ContactType,
	FooterType,
	HeaderType,
	PriceType,
	ProductType,
} from "../../types/strapi-types";
import { Price } from "../../components/price/price";
import { Loader } from "../../components/loader/loader";
import { Products } from "../../components/products/products";

export function OfferPage() {
	const { data: headerData } = useStrapiFetch<HeaderType>(
		"/api/header?populate=bg&populate=logo"
	);
	const { data: priceData } = useStrapiFetch<PriceType>(
		"/api/price?populate=image"
	);
	const { data: productsData } = useStrapiFetch<ProductType[]>("/api/products");
	const { data: contactData } = useStrapiFetch<ContactType>("/api/contact");
	const { data: footerData } = useStrapiFetch<FooterType>("/api/footer");

	if (
		!headerData ||
		!priceData ||
		!productsData ||
		!contactData ||
		!footerData
	) {
		return <Loader />;
	}

	return (
		<>
			<Header minimize data={headerData} />

			<main className="relative">
				<Bg />

				<Price data={priceData} />

				<Products data={productsData} />

				<Contact data={contactData} />
			</main>

			<Footer data={footerData} />
		</>
	);
}
