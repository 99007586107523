import { useEffect, useRef, useState } from "react";
import type { StrapiReturn } from "../../types/strapi-return";
import type { ProductType } from "../../types/strapi-types";

export function Product({ data }: { data: StrapiReturn<ProductType> }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const productRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const { title, items } = data.attributes;

	const itemsList = items.split("\n");

	useEffect(() => {
		function handleMouse(event: MouseEvent) {
			if (!isExpanded || !productRef.current || !event.target) {
				return;
			}

			if (productRef.current.contains(event.target as Node)) {
				return;
			}

			setIsExpanded(false);
		}

		document.addEventListener("click", handleMouse);
		return () => document.removeEventListener("click", handleMouse);
	}, [productRef, isExpanded]);

	useEffect(() => {
		if (!contentRef.current) {
			return;
		}

		if (isExpanded) {
			contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
			contentRef.current.style.transform = "translateY(0)";
			return;
		}

		contentRef.current.style.height = "0";
		contentRef.current.style.transform = "translateY(-1px)";
	}, [contentRef, isExpanded]);

	return (
		<div ref={productRef} id={`products-${title.replaceAll(" ", "_")}`}>
			<button
				className="flex justify-between items-center p-2 border-[var(--main-color)] border-2 relative cursor-pointer w-full"
				onClick={() => setIsExpanded((prev) => !prev)}
			>
				<h4 className="text-lg font-semibold text-start">{title}</h4>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="none"
					stroke="var(--main-color)"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`w-8 h-8 transition-transform ${
						isExpanded ? "rotate-180" : ""
					}`}
				>
					<path d="m18 15-6-6-6 6" />
				</svg>
			</button>

			<div
				ref={contentRef}
				className="duration-500 overflow-hidden border-[#ccc] border border-t-0"
			>
				{itemsList.map((item) => (
					<div
						key={item}
						className="p-2 border-[var(--main-color)] border-b-2 border-x-2"
					>
						{item}
					</div>
				))}
			</div>
		</div>
	);
}
