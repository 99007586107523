import type { StrapiReturn } from "../../types/strapi-return";
import type { OfferType } from "../../types/strapi-types";
import { Icon } from "./icon";

export function Offer({ data }: { data?: StrapiReturn<OfferType[]> }) {
	if (!data) {
		return null;
	}

	return (
		<div className="w-full max-w-[1200px] mx-auto px-4 mt-6 py-10 md:py-14 z-10 relative">
			<div className="flex flex-col gap-4 justify-center items-center">
				<h3 className="font-bold text-2xl sm:text-3xl md:text-4xl relative pb-2 after:absolute after:bottom-0 after:left-0 after:bg-[#eee] after:w-full after:h-[2px]">
					Oferta
				</h3>

				<p className="text-center text-xs font-bold tracking-[4px] md:tracking-[8px]">
					WARZYWA ‧ OWOCE ‧ PRZETWORY
				</p>
			</div>

			<div className="flex flex-col sm:flex-row gap-6 justify-center items-center mt-12">
				{data.map(({ attributes }, i) => (
					<Icon key={`offer-${i}`} {...attributes} />
				))}
			</div>
		</div>
	);
}
