import { NavLink } from "react-router-dom";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { StrapiReturn } from "../../types/strapi-return";
import type { AboutType } from "../../types/strapi-types";
import { Markdown } from "../common/markdown";

export function About({ data }: { data?: StrapiReturn<AboutType> }) {
	if (!data) {
		return null;
	}

	const { title, text, image } = data.attributes;
	const { url, alternativeText } = image.data.attributes;

	return (
		<div
			id="o-nas"
			className="w-full max-w-[1200px] mx-auto px-4 mt-4 py-10 md:py-14 z-10 relative flex flex-col md:flex-row gap-6 md:gap-10 justify-center items-center"
		>
			<div className="flex flex-col items-center md:items-start gap-4 md:gap-8 flex-1">
				<h1 className="font-bold text-2xl sm:text-3xl md:text-4xl relative">
					{title}
				</h1>

				<Markdown>{text}</Markdown>

				<NavLink to="/oferta" className="text-[var(--main-color)] bg-white hover:bg-[var(--main-color)] hover:text-white transition-colors duration-300 border-2 border-[var(--main-color)] font-bold px-8 py-3">
					ZOBACZ OFERTĘ
				</NavLink>
			</div>

			<div className="w-full flex-1">
				<div className="w-full h-0 pb-[100%] relative">
					<img
						src={getStrapiImageUrl(url)}
						alt={alternativeText}
						className="absolute top-0 left-0 w-full h-full object-cover"
					/>
				</div>
			</div>
		</div>
	);
}
