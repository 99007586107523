import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { getStrapiUri } from "../../lib/get-strapi-uri";
import { useState } from "react";

export function ContactForm() {
	const formSchema = z.object({
		firstName: z.string().trim().min(2, "Imie jest za krotkie"),
		secondName: z.string().trim().min(2, "Nazwisko jest za krotkie"),
		email: z.string().trim().email("Niepoprawny email"),
		tel: z.string().trim().min(9, "Niepoprawny numer telefonu"),
		text: z
			.string()
			.trim()
			.min(10, "Wiadomosc jest za krotka")
			.max(5000, "Wiadomosc jest za długa"),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			firstName: "",
			secondName: "",
			email: "",
			tel: "",
			text: "",
		},
	});

	const isSubmitting = form.formState.isSubmitting;

	const { errors } = form.formState;

	const [submitError, setSubmitError] = useState("");
	const [onSuccess, setOnSuccess] = useState("");

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			setSubmitError("");
			setOnSuccess("");

			const formData = new FormData();
			formData.append("data", JSON.stringify({ ...values }));

			await axios.post(getStrapiUri("/api/contact-requests"), formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			setOnSuccess("Wiadomość została wysłana");

			form.reset();
		} catch (error) {
			setSubmitError("Wystąpił błąd podczas wysyłania wiadomości");
		}
	}

	return (
		<FormProvider {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4"
			>
				<Controller
					control={form.control}
					name="firstName"
					render={({ field }) => (
						<div>
							<input
								{...field}
								placeholder="IMIĘ"
								autoComplete="given-name"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full"
							/>
							{errors.firstName && (
								<p className="text-red-600 text-sm">
									{errors.firstName.message}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="secondName"
					render={({ field }) => (
						<div>
							<input
								{...field}
								placeholder="NAZWISKO"
								autoComplete="family-name"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full"
							/>
							{errors.secondName && (
								<p className="text-red-600 text-sm">
									{errors.secondName.message}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="tel"
					render={({ field }) => (
						<div>
							<input
								{...field}
								type="tel"
								placeholder="TELEFON"
								autoComplete="tel"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full"
							/>
							{errors.tel && (
								<p className="text-red-600 text-sm">{errors.tel.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="email"
					render={({ field }) => (
						<div>
							<input
								{...field}
								type="email"
								placeholder="EMAIL"
								autoComplete="email"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="text"
					render={({ field }) => (
						<div className="lg:col-span-2">
							<textarea
								{...field}
								rows={8}
								placeholder="TREŚĆ WIADOMOŚCI"
								disabled={isSubmitting}
								className="border-2 border-[var(--main-color)] p-2 w-full"
							/>
							{errors.text && (
								<p className="text-red-600 text-sm">{errors.text.message}</p>
							)}
						</div>
					)}
				/>

				{!!submitError && (
					<div className="lg:col-span-2 text-center text-lg text-red-600">
						<p>{submitError}</p>
					</div>
				)}

				{!!onSuccess && (
					<div className="lg:col-span-2 text-center text-lg text-[var(--main-color)]">
						<p>{onSuccess}</p>
					</div>
				)}

				<div className="lg:col-span-2 flex justify-center">
					<button
						type="submit"
						disabled={isSubmitting}
						className="w-full max-w-[400px] text-white bg-[var(--main-color)] hover:bg-white hover:text-[var(--main-color)] transition-colors duration-300 border-2 border-[var(--main-color)] font-bold px-8 py-3"
					>
						{isSubmitting ? "Wysyłanie..." : "Wyślij wiadomość"}
					</button>
				</div>
			</form>
		</FormProvider>
	);
}
