import type { StrapiReturn } from "../../types/strapi-return";
import type { ProductType } from "../../types/strapi-types";
import { Product } from "./product";

export function Products({ data }: { data?: StrapiReturn<ProductType[]> }) {
	if (!data) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4 md:gap-8 w-full max-w-[1200px] mx-auto px-4 mt-4 py-10 md:py-14 z-10 relative">
			{data.map((product) => (
				<Product key={product.id} data={product} />
			))}
		</div>
	);
}
