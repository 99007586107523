import "./loader.css";

export function Loader() {
	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-white z-[10000]">
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
