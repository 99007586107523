import type { StrapiReturn } from "../../types/strapi-return";
import type { FooterType } from "../../types/strapi-types";

export function Footer({ data }: { data?: StrapiReturn<FooterType> }) {
	if (!data) {
		return null;
	}

	const { text } = data.attributes;

	return (
		<footer className="mt-6">
			<div className="h-px bg-[#eee]" />

			<div className="w-full max-w-[1200px] mx-auto px-4 py-5">
				<p className="text-center text-sm">{text}</p>
			</div>
		</footer>
	);
}
