import { Loader } from "../components/loader/loader";
import { useStrapiFetch } from "../hooks/use-strapi-fetch";
import { getStrapiImageUrl } from "../lib/get-strapi-image-url";
import type { StyleType } from "../types/strapi-types";

export function StyleLayout({ children }: { children: React.ReactNode }) {
	const { data } = useStrapiFetch<StyleType>("/api/style?populate=bg");

	if (!data) {
		return <Loader />;
	}

	const { color, bg } = data.attributes;
	const url = bg?.data?.attributes?.url;

	return (
		<div
			style={
				{
					"--main-color": color,
					"--bg-image": url ? `url(${getStrapiImageUrl(url)})` : "",
				} as React.CSSProperties
			}
		>
			{children}
		</div>
	);
}
