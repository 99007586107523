import { create } from "zustand";

interface AppErrorStore {
	isError: boolean;
	setError: () => void;
}

export const useAppErrorStore = create<AppErrorStore>((set, get) => ({
	isError: false,
	setError: () => set({ isError: true }),
}));
