import { NavLink } from "react-router-dom";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { OfferType } from "../../types/strapi-types";

export function Icon({ image, title }: OfferType) {
	const { url, alternativeText } = image.data.attributes;

	return (
		<NavLink
			to={`/oferta`}
			className="flex flex-col w-full max-w-[360px] relative group"
		>
			<div className="w-full h-0 pb-[105%] relative overflow-hidden">
				<img
					src={getStrapiImageUrl(url)}
					alt={alternativeText}
					className="absolute top-0 left-0 w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
				/>
			</div>

			<p className="text-white font-bold p-2 bg-black text-center">{title}</p>

			<div className="h-1 bg-[var(--main-color)]" />
		</NavLink>
	);
}
