import { useMediaQuery } from "../../../hooks/use-media-query";
import { NavigationLinks } from "./navigation-links";
import { NavigationMenu } from "./navigation-menu";

export function Navigation({ tel }: { tel: string }) {
	const { isMobile } = useMediaQuery();

	return (
		<div className="text-white font-medium">
			{isMobile ? (
				<NavigationMenu tel={tel} />
			) : (
				<nav className="flex flex-wrap items-center gap-8">
					<NavigationLinks tel={tel} />
				</nav>
			)}
		</div>
	);
}
