import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { StrapiReturn } from "../../types/strapi-return";
import type { PriceType } from "../../types/strapi-types";
import { Markdown } from "../common/markdown";

export function Price({ data }: { data?: StrapiReturn<PriceType> }) {
	if (!data) {
		return null;
	}

	const { text, image } = data.attributes;
	const { url, alternativeText } = image.data.attributes;

	return (
		<div className="flex flex-col gap-4 md:gap-8 w-full max-w-[1200px] mx-auto px-4 mt-6 py-10 md:py-14 z-10 relative">
			<div className="flex items-center gap-8">
				<h3 className="font-bold text-2xl sm:text-3xl md:text-4xl relative">
					Cennik
				</h3>

				<div className="bg-[#eee] h-[2px] flex-1" />
			</div>

			<div className="flex flex-col md:flex-row gap-6 md:gap-10 justify-center items-center">
				<div className="flex-1">
					<Markdown>{text}</Markdown>
				</div>

				<div className="w-full flex-1">
					<div className="w-full h-0 pb-[50%] relative">
						<img
							src={getStrapiImageUrl(url)}
							alt={alternativeText}
							className="absolute top-0 left-0 w-full h-full object-cover shadow-md"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
