import type { StrapiReturn } from "../../types/strapi-return";
import type { ContactType } from "../../types/strapi-types";
import { Markdown } from "../common/markdown";
import { ContactForm } from "./contact-form";

export function Contact({ data }: { data?: StrapiReturn<ContactType> }) {
	if (!data) {
		return null;
	}

	const { text } = data.attributes;

	return (
		<div
			id="kontakt"
			className="w-full max-w-[1200px] mx-auto px-4 mt-4 py-10 md:py-14 z-10 relative flex flex-col md:flex-row gap-6 md:gap-10 justify-center items-center md:items-start"
		>
			<div className="flex flex-col items-center md:items-start gap-4 md:gap-8 w-full flex-1">
				<h3 className="font-bold text-2xl sm:text-3xl md:text-4xl relative">
					FORMULARZ KONTAKTOWY
				</h3>

				<ContactForm />
			</div>

			<div className="flex flex-col items-center md:items-start gap-4 md:gap-8 flex-1">
				<h3 className="font-bold text-2xl sm:text-3xl md:text-4xl relative">
					DANE KONTAKTOWE
				</h3>

				<Markdown>{text}</Markdown>
			</div>
		</div>
	);
}
