import ReactMarkdown from "react-markdown";

export function Markdown({
	children,
	className = "",
}: {
	children: string;
	className?: string;
}) {
	return (
		<ReactMarkdown
			className={`text-sm md:text-base !leading-loose [&_h3]:text-lg sm:[&_h3]:text-xl md:[&_h3]:text-2xl sm:[&_h4]:text-base md:[&_h4]:text-lg hover:[&_a]:text-[var(--main-color)] [&_li]:list-disc [&_li]:list-inside ${className}`}
		>
			{children}
		</ReactMarkdown>
	);
}
