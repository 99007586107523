import axios from "axios";
import { useState, useEffect } from "react";
import { useAppErrorStore } from "./use-app-error-store";
import { getStrapiUri } from "../lib/get-strapi-uri";
import type { StrapiReturn } from "../types/strapi-return";

export function useStrapiFetch<T>(uri: string) {
	const [data, setData] = useState<StrapiReturn<T>>();
	const { setError } = useAppErrorStore();

	useEffect(() => {
		async function fetchBars() {
			try {
				const strapiUri = getStrapiUri(uri);
				const res = await axios.get(strapiUri);
				const data = res.data.data as StrapiReturn<T>;
				setData(data);
			} catch (e: unknown) {
				setError();
			}
		}
		fetchBars();
	}, [setError, uri]);

	return { data };
}
