import { Error } from "./components/error/error";
import { useAppErrorStore } from "./hooks/use-app-error-store";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";
import { MainPage } from "./pages/main-page/main-page";
import { OfferPage } from "./pages/offer-page/offer-page";

export default function App() {
	const { isError } = useAppErrorStore();

	return (
		<div
			id="start"
			className={`font-sans overflow-hidden ${isError ? "h-screen" : ""}`}
		>
			{isError && <Error />}

			<Routes>
				<Route path="" element={<MainPage />} />
				<Route path="/oferta" element={<OfferPage />} />
				<Route path="/*" element={<NotFoundPage />} />
			</Routes>
		</div>
	);
}
