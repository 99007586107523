import { useEffect, useState } from "react";

export function Bg() {
	const [top, setTop] = useState(0);

	useEffect(() => {
		function handleScroll() {
			if (window.scrollY < 0) {
				requestAnimationFrame(() => setTop(0));
				return;
			}
			requestAnimationFrame(() => setTop(window.scrollY * 0.1));
		}

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			className="absolute top-0 left-0 w-full h-full min-h-[2000px] bg-contain bg-no-repeat hidden md:block -z-10"
			style={{ backgroundImage: "var(--bg-image)", top: `${top}px` }}
		/>
	);
}
